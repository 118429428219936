import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import "focus-visible"; // Focus Visible Polyfill

// Internal Modules
import "./modules/nav";
import "./modules/theme";
import "./modules/orama";

// Instantiate Alpine.js
Alpine.plugin(focus);
Alpine.start();

window.Alpine = Alpine;
