// @ref: https://www.raymondcamden.com/2022/07/19/an-example-of-algolia-search-with-alpinejs

import { OramaClient } from "@oramacloud/client";

const oramaClient = new OramaClient({
  endpoint: "https://cloud.orama.run/v1/indexes/ripandis-msmau6",
  api_key: "o2Z3SKMYshXBBYydXwgmY25vHApD02ja",
});

window.oramaClient = oramaClient;

// document.addEventListener("alpine:init", () => {
//   Alpine.data("app", async () => ({
//     async init() {
//       const results = await oramaClient.search({
//         term: "reset",
//         limit: 5,
//       });
//       this.searchReady = true;
//     },
//     index: null,
//     term: "",
//     searchReady: false,
//     noResults: false,
//     results: null,
//     totalHits: null,
//     resultsPerPage: null,
//     async search() {
//       if (this.term === "") return;
//       this.noResults = false;
//       console.log(`search for ${this.term}`);

//       // let rawResults = await this.index.search(this.term);
//       const rawResults = await this.index.search(this.term, {
//         attributesToSnippet: ["content"],
//       });

//       if (rawResults.nbHits === 0) {
//         this.noResults = true;
//         return;
//       }
//       this.totalHits = rawResults.nbHits;
//       this.resultsPerPage = rawResults.hitsPerPage;
//       this.results = rawResults.hits.map((h) => {
//         h.snippet = h._snippetResult.content.value;
//         h.date = new Intl.DateTimeFormat("en-us").format(new Date(h.date));
//         return h;
//       });
//     },
//   }));
// });
